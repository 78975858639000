@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .credit-card-img {
    background-image: url('../assets/images/credit.svg');
  }
}
@media (max-width: 640px) {
  div[data-overlay-container='true'],
  body {
    overflow-x: hidden;
  }
}

input[data-slot='input'] {
  font-size: 16px !important;
}

div[data-overlay-container='true']:has(.sideBarOpening),
body:has(.sideBarOpening) {
  overflow: hidden !important;
}
.arPrice {
  unicode-bidi: isolate-override;
  direction: ltr;
}
.flower-img {
  background-image: url('../assets/images/third_flower.png');
}
.ribbon-image {
  background-image: url('../assets/images/ribbon.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.gmnoprint a,
.gmnoprint span {
  display: none;
}
.gmnoprint div {
  background: none !important;
}
.gm-style > div > div > a > div {
  display: none;
}
.gm-style-cc {
  display: none;
}
.react-stacked-center-carousel-slide-0,
.react-stacked-center-carousel-slide-1,
.react-stacked-center-carousel-slide--1,
.react-stacked-center-carousel-slide--2 {
  justify-content: center !important;
}

@media (max-width: 640px) {
  .deliveryMap div[style='position: absolute; right: 0px; top: 0px;'] {
    width: 96%;
  }
}

[data-placement='bottom']::before {
  left: 79.7% !important;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid #d6bdd0;
}

.arrow-up::before {
  content: '';
  position: absolute;
  top: 0.063rem;
  left: -1.25rem;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid #fff;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transform: scale(0.95);
  transition:
    opacity 0.3s ease,
    transform 0.3s ease;
}

.modal.open {
  opacity: 1;
  transform: scale(1);
}

.modal.hidden {
  opacity: 0;
  transform: scale(0.95);
  display: none;
}

div[role='spinbutton'],
div[data-type='literal'] {
  color: #382476 !important;
}
.signup-calander div:nth-child(2) {
  width: auto !important;
}

.background_image_faq {
  background-image: url('../assets/images/faq.jpg');
  background-size: cover;
  background-position: center;
}
.background_image_about {
  background-image: url('../assets/images/about.jpg');
  background-size: cover;
  background-position: center;
}

.background_image_policy {
  background-image: url('../assets/images/policy.jpg');
  background-size: cover;
  background-position: center;
}
.background_image_planning {
  background-image: url('../assets/images/planning.jpg');
  background-size: cover;
  background-position: center;
}
.rtl {
  direction: rtl;
}
